import defaultTheme from '@bluheadless/ui/src/theme/default'
import { createTheme as createMuiTheme } from '@mui/material/styles' // keep @mui/material/styles to use default theme of @mui/material
import { createTheme } from '@mui/system'
import CssBaseline from './theme/components/css-baseline'
import Button from './theme/components/button'
import SvgIcon from './theme/components/svg-icon'
import BHInput from './theme/components/form/bh-input'
import Input from './theme/components/form/input'
import Banners from './theme/components/banners'
import IconButton from './theme/components/icon-button'
import BHNewsletter from './theme/components/bh-newsletter'

import { fonts } from './theme/fonts'

const htmlFontSize = 14
const fontSize = 14

const muiTheme = createMuiTheme({ typography: { htmlFontSize, fontSize } })

const fontFamily = fonts.helveticaNeueLTStd.fontFamily
const fontFamilyAlternative = fontFamily

const theme = createTheme(defaultTheme, {
	palette: {
		common: {
			black: '#000000',
			white: '#ffffff',
		},
		primary: {
			main: '#000000',
			light: '#333333',
			dark: '#000000',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#ffffff',
			light: '#fcfcfc',
			dark: '#fcfcfc',
			contrastText: '#000000',
		},
		grey: {
			main: '#757575',
			input: '#757575',
			border: '#EAEAEA',
			label: '#AFAFAF',
			light: '#959595',
			breadcrumbs: '#9F9F9F',
			vat: '#A0A0A0',
		},
		footer: {
			bgTop: '#FFFFFF',
			bgMiddleTop: '#FFFFFF',
			bgMiddle: '#FFFFFF',
			bgMiddleBottom: '#FFFFFF',
			bgBottom: '#FFFFFF',
			border: '#ECECEC',
			color: '#000000',
			copyright: '#000000',
			service: '#F2F2F2',
		},
		background: {
			paper: '#FFFFFF',
			default: '#FFFFFF',
			header: '#FFFFFF',
			card: '#F6F6F6',
		},
		tile: {
			background: '#ffffff',
		},
		button: {
			disabled: '#E1E1E1',
		},
	},
	typography: {
		fontFamily,
		fontFamilyAlternative,
		htmlFontSize,
		fontSize,
		fontWeight: 400,
		fontWeightBold: 700,
		fontWeightExtraBold: 900,
		lineHeight: '16px',
		textTransform: 'lowercase',
		headlinebig: {
			...fonts.aGaramondProItalic,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: '28px',
			letterSpacing: '2px',
			fontWeight: 400,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(32),
				lineHeight: '40px',
			},
		},
		headlinemedium: {
			...fonts.aGaramondProItalic,
			fontSize: muiTheme.typography.pxToRem(32),
			lineHeight: '40px',
			letterSpacing: '2px',
			fontWeight: 400,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(32),
				lineHeight: '40px',
			},
		},
		headlinesmall: {
			...fonts.aGaramondProItalic,
			fontSize: muiTheme.typography.pxToRem(32),
			lineHeight: '40px',
			letterSpacing: '2px',
			fontWeight: 400,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(32),
				lineHeight: '40px',
			},
		},
		subheadline1: {
			...fonts.helveticaNeueLTStd,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: '22px',
			letterSpacing: '1px',
			fontWeight: 400,
		},
		subheadline2: {
			...fonts.helveticaNeueLTStd,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: '22px',
			letterSpacing: '1px',
			fontWeight: 400,
		},
		headlineDescription: {
			...fonts.helveticaNeueLTStd,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: '22px',
			letterSpacing: '1px',
			fontWeight: 400,
		},
		h1: {
			...fonts.aGaramondProItalic,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: '32px',
			letterSpacing: '2px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(24),
				lineHeight: '32px',
			},
		},
		h2: {
			...fonts.aGaramondProItalic,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: '40px',
			letterSpacing: '2px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(24),
				lineHeight: '40px',
			},
		},
		h3: {
			...fonts.aGaramondProItalic,
			fontSize: muiTheme.typography.pxToRem(24),
			letterSpacing: '2px',
			lineHeight: '40px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(24),
				lineHeight: '40px',
			},
		},
		h4: {
			...fonts.aGaramondProItalic,
			fontSize: muiTheme.typography.pxToRem(20),
			lineHeight: '30px',
			letterSpacing: '2px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(20), // override BluHeadless
				lineHeight: '30px',
			},
		},
		h5: {
			...fonts.aGaramondProItalic,
			fontSize: muiTheme.typography.pxToRem(18),
			lineHeight: '24px',
			letterSpacing: '2px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(18), // override BluHeadless
				lineHeight: '24px',
			},
		},
		h6: {
			...fonts.aGaramondProItalic,
			fontSize: muiTheme.typography.pxToRem(18),
			lineHeight: 1,
			letterSpacing: 0,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(18), // override BluHeadless
				lineHeight: 1,
			},
		},
		body1: {
			...fonts.helveticaNeueLTStd,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: 1,
			fontWeight: 400,
			letterSpacing: '1px',
		},
		body2: {
			...fonts.helveticaNeueLTStd,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1,
			fontWeight: 400,
			letterSpacing: '1px',
		},
		body3: {
			...fonts.aGaramondProItalic,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: '20px',
			fontWeight: 400,
			letterSpacing: '1px',
		},
		button: {
			...fonts.helveticaNeueLTStd,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: '16px',
			letterSpacing: '2px',
			textTransform: 'lowercase',
		},
		copyright: {
			...fonts.helveticaNeueLTStd,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: '24px',
			fontWeight: 400,
			textAlign: 'center',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				textAlign: 'left',
			},
		},
		caption: {
			...fonts.helveticaNeueLTStd,
		},
		overline: {
			...fonts.helveticaNeueLTStd,
		},
		subtitle1: {
			...fonts.helveticaNeueLTStd,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1,
			letterSpacing: 0,
			fontWeight: 400,
		},
	},

	scrollbar: `
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color: var(--color-brand) #F7F7F7; // thumb, track

		::-webkit-scrollbar {
			width: 7px;
			height: 5px;
		}

		//track
		::-webkit-scrollbar-track {
			background: #F7F7F7; // grey.lighter
			border-radius: 3.5px;
		}

		//handle
		::-webkit-scrollbar-thumb {
			background: var(--color-brand);
			border-radius: 3.5px;
		}

		//handle on hover
		::-webkit-scrollbar-thumb:hover {
			background: #373737; // grey.dark
		}
	`,

	scrollbarJsx: {
		scrollbarWidth: 'auto',
		scrollbarColor: 'var(--color-brand) #F7F7F7', // thumb, track

		'::-webkit-scrollbar': {
			width: 7,
		},
		//track
		'::-webkit-scrollbar-track': {
			background: '#F7F7F7', // grey.lighter
			borderRadius: 3.5,
		},
		//handle
		'::-webkit-scrollbar-thumb': {
			background: 'var(--color-brand)',
			borderRadius: 3.5,
		},
		//handle on hover
		'::-webkit-scrollbar-thumb:hover': {
			background: '#373737', // grey.dark
		},
	},

	components: {
		...Banners,
		...Button,
		...CssBaseline,
		...SvgIcon,
		...BHInput,
		...Input,
		...IconButton,
		...BHNewsletter,
	},
})

export default theme
