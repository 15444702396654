/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const ArrowheadDownIconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			d="M4.768 7.92L12 16.08l7.232-8.16"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.536}
		/>
	</svg>
))
ArrowheadDownIconSvg.displayName = 'ArrowheadDownIconSvg'

const ArrowheadDownIcon = forwardRef((props, ref) => <Icon component={ArrowheadDownIconSvg} ref={ref} {...props} />)
ArrowheadDownIcon.displayName = 'ArrowheadDownIcon'

ArrowheadDownIcon.defaultProps = {
	...Icon.defaultProps,
}
ArrowheadDownIcon.propTypes = {
	...Icon.propTypes,
}

export default ArrowheadDownIcon
export { ArrowheadDownIconSvg }
