export const fontSize = 14
export const htmlFontSize = 14
export const fonts = {
	aGaramondPro: {
		fontFamily: '"AGaramondPro-Regular"',
		fontWeight: 400,
	},
	aGaramondProItalic: {
		fontFamily: '"AGaramondPro-Italic"',
		fontWeight: 400,
	},
	aGaramondProBold: {
		fontFamily: '"AGaramondPro-Bold"',
		fontWeight: 900,
	},
	aGaramondProBoldItalic: {
		fontFamily: '"AGaramondPro-BoldItalic"',
		fontWeight: 900,
	},
	helveticaNeueLTStd: {
		fontFamily: '"HelveticaNeueLTStd-Lt"',
		fontWeight: 400,
	},
	helveticaNeueLTStdMd: {
		fontFamily: '"HelveticaNeueLTStd-Md"',
		fontWeight: 700,
	},
}
