const SvgIcon = {
	MuiSvgIcon: {
		styleOverrides: {
			root: {
				fill: 'none',
			},
			fontSizeExtraSmall: {
				fontSize: '12px',
			},
			fontSizeSmall: {
				fontSize: '18px',
			},
			fontSizeMiddle: {
				fontSize: '24px',
			},
			fontSizeMedium: {
				fontSize: '24px',
			},
			fontSizeButton: {
				fontSize: '20px',
			},
		},
	},
}

export default SvgIcon
