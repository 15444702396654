const BHInput = {
	BHInput: {
		defaultProps: {
			variant: 'standard',
			InputLabelProps: { shrink: true },
		},
	},
}

export default BHInput
