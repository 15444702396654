/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const XIconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path fill="none" d="M0 0h24v24H0z" />
		<path
			d="M4.5 19.5L12 12m0 0l7.5-7.5M12 12L4.5 4.5M12 12l7.5 7.5"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
))
XIconSvg.displayName = 'XIconSvg'

const XIcon = forwardRef((props, ref) => <Icon component={XIconSvg} ref={ref} {...props} />)
XIcon.displayName = 'XIcon'

XIcon.defaultProps = {
	...Icon.defaultProps,
}
XIcon.propTypes = {
	...Icon.propTypes,
}

export default XIcon
export { XIconSvg }
