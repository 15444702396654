import { fonts } from '../fonts'

const CssBaseline = {
	MuiCssBaseline: {
		styleOverrides: {
			html: {
				...fonts.helveticaNeueLTStd,
				// fontsize
				'--size-small': '10px',
				'--size-base': '12px',
				'--size-medium': '14px',
				'--size-big': '16px',
				'--size-biggest': '18px',
				// font-family
				'--font-garamond-regular': '"AGaramondPro-Regular"',
				'--font-garamond-italic': '"AGaramondPro-Italic"',
				'--font-garamond-black': '"AGaramondPro-Bold"',
				'--font-garamond-black-italic': '"AGaramondPro-BoldItalic"',
				'--font-base': '"HelveticaNeueLTStd-Lt"',
				'--font-base-bold': '"HelveticaNeueLTStd-Md"',
				// spacing
				'--spacing': '8px',
				// brand
				'--color-brand': '#000000',
				// default
				'--color-primary': '#000000',
				'--color-secondary': '#ffffff',
				// system
				'--color-hover': '#757575',
				'--color-error': '#A21A10',
				'--color-success': '#457030',
				'--color-warning': '#F5A623',
				'--color-disabled': '#9A9A9A',
				// others
				'--color-grey0': '#F7F7F7',
				'--color-grey10': '#2A2A2A',
				'--color-grey20': '#525252',
				'--color-grey30': '#757575',
				'--color-grey40': '#9A9A9A',
				'--color-grey50': '#B8B8B8',
				'--color-grey60': '#CFCFCF',
				'--color-grey70': '#E1E1E1',
				'--color-grey80': '#EEEEEE',
				'--color-grey90': '#F8F8F8',
				'--color-brown': '#704214',
				'--color-green': '#008000',
				'--color-blue': '#0D31AB',
				'--color-purple': '#800080',
				'--color-pink': '#FFB6C1',
				'--color-red': '#FF0000',
				'--color-orange': '#F28413',
				'--color-yellow': '#FFE400',
				// socials
				'--color-facebook': '#3B5998',
				'--color-instagram': '#833ab4',
				'--color-pinterest': '#bd091c',
				'--color-twitter': '#1DA1F2',
				'--color-youtube': '#e7221a',
				// buttons
				'--button-primary-size': '14px',
				'--button-primary-color': 'var(--color-secondary)',
				'--button-primary-bgcolor': 'var(--color-primary)',
				'--button-primary-hover-bgcolor': 'var(--color-grey10)',
				'--button-secondary-size': '14px',
				'--button-secondary-color': 'var(--color-primary)',
				'--button-secondary-bgcolor': 'var(--color-secondary)',
				// links
				'--link-primary-size': '14px',
				// animations
				'--transition': '.3s cubic-bezier(0.4, 0, 0.2, 1) 0s',
			},
			body: {
				...fonts.helveticaNeueLTStd,
				textTransform: 'lowercase',
				color: 'var(--color-primary)',
				'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
					{
						'-webkit-box-shadow': '0 0 0 30px white inset !important',
					},
			},
			hr: {
				border: 'none',
				borderTop: '1px solid var(--color-grey60)',
			},
		},
	},
}

export default CssBaseline
