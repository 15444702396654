/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const NotificationsIconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			d="M18.134 11C18.715 16.375 21 18 21 18H3s3-2.133 3-9.6c0-1.697.632-3.325 1.757-4.525C8.883 2.675 10.41 2 12 2c.337 0 .672.03 1 .09M19 8a3 3 0 100-6 3 3 0 000 6zM13.73 21a1.999 1.999 0 01-3.46 0"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
))
NotificationsIconSvg.displayName = 'NotificationsIconSvg'

const NotificationsIcon = forwardRef((props, ref) => <Icon component={NotificationsIconSvg} ref={ref} {...props} />)
NotificationsIcon.displayName = 'NotificationsIcon'

NotificationsIcon.defaultProps = {
	...Icon.defaultProps,
}
NotificationsIcon.propTypes = {
	...Icon.propTypes,
}

export default NotificationsIcon
export { NotificationsIconSvg }
